@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Medium'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins-Light'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
               'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
               sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

.animate-marquee-delay {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  animation-delay: 5s;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.3); /* Start smaller */
  }
  50% {
    opacity: 1;
    transform: scale(1.1); /* Grow larger than normal */
  }
  70% {
    opacity:1;
    transform: scale(0.95); /* Slightly shrink for bounce effect */
  }
  100% {
    transform: scale(1); /* End at normal size */
    opacity:1;
  }
}


.pop-in {
  opacity: 0;
  animation: popIn 2s ease forwards; /* Adjust duration and easing as needed */
}
